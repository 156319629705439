import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import submitStandardForm from '/src/functions/forms/submitStandardForm'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const RequiredText = loadable(() => import('/src/components/Form/Required/RequiredText'))
const SelectTitle = loadable(() => import('/src/components/Form/Specialised/SelectTitle'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))
const TextField = loadable(() => import('/src/components/Form/TextField'))

const validationSchema = Yup.object({
    title: Yup.string(),
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    email: Yup.string().email('Email address is not valid').required('Email is required')
})

const Over50sGuideForm = ({ disclaimer }) => {
    const [isSubmitted, setSubmitted] = useState(false)

    return (
        <Formik
            initialValues={{
                title: '',
                firstname: '',
                lastname: '',
                email: ''
            }}
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    title: values.title,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    email: values.email
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'over-50s-guide',
                    firebaseData: firebaseData,
                    confirmationEmail: process.env.GATSBY_MARKETING_EMAIL_ADDRESS,
                    emailTemplate: 'over-50s-guide-form-template',
                    fileDownload: {
                        location: '../Over-50s-Life-Insurance-Protect-Line.pdf',
                        fileName: 'Over-50s-Life-Insurance-Protect-Line.pdf'
                    }
                }

                setSubmitted(true)

                await submitStandardForm(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => {
                return (
                    <Form>
                        <RequiredText />

                        <FormRow>
                            <SelectTitle label={'Title'} isSubmitting={isSubmitting} isSubmitted={isSubmitted} />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={'First name'}
                                name={'firstname'}
                                placeholder={'e.g. Emma'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                                required
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={'Last name'}
                                name={'lastname'}
                                placeholder={'e.g. Jones'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                                required
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={'Email address'}
                                name={'email'}
                                type={'email'}
                                placeholder={'e.g. name@email.com'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                                required
                            />
                        </FormRow>

                        {disclaimer && <Alert content={disclaimer} />}

                        <CtaContainer align={'right'}>
                            <SubmitButton
                                initialText={'Request my copy'}
                                sentText={'Guide downloading...'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                                disabled={!(isValid && dirty)}
                            />
                        </CtaContainer>

                        {isSubmitted === true && (
                            <Alert
                                variant={'info'}
                                content={`<p>If your download didn't start automatically, <a href="../over-50s-guide.pdf" download>download it here</a>.</p>`}
                            />
                        )}
                    </Form>
                )
            }}
        </Formik>
    )
}

Over50sGuideForm.propTypes = {
    /**
     * Disclaimer
     */
    disclaimer: PropTypes.string
}

Over50sGuideForm.defaultProps = {
    disclaimer: '<p>This is the disclaimer message</p>'
}

export default Over50sGuideForm
